import React, { useRef, useState , useEffect, useContext} from "react";

import { DETAILS } from '../files/Data';

import '../index.css';
import Header from "../element/Header";
import Footer1 from "../element/ButtonNav1";
import SplashPage from "../element/SplashPage";


function Contact() {

  const [customMargin, setCustomMargin] = useState(75);
  const [loadingTime, setLoadtingTime] = useState(true);
 

  useEffect(() => {
    document.title = 'Contact | ' + DETAILS.site_title;

    const fetchData = async () => {
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1900);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);
 

  function callBackCall(){ window.location.href = `tel:${DETAILS.phone_no}`; }
  function callBackWhatsapp(){ window.open(`https://wa.me/${DETAILS.phone_no}`, '_blank'); } 



  return(
    <div>
      <head>
        <title>VIP Road Escort Service Zirakpur</title>
        <meta name="description" content="VIP Road Escort Service Zirakpur, Chandigarh, Mohali and Panchkula are professional in handling the lusty side of every men with very cheap rates and good service.Book sexy female escort for sexual needs. Contact number 8168682300. Services are available 24*7 . Our service is the fastest door delivery service of escort with COD. Book online on WhatsApp or on the call for hot and sexy girls . Our escort service girls are prepared for both IN-OUT call services with full sex . We have best and  professional escort call which you should must try. Give us the chance to serve you. Contact number 8168682300" />
      </head>

      <div className="flex flex-col">
        {( loadingTime) ? ( <SplashPage /> ) : (
          <div className="flex flex-col justify-between" >

            <Header callBack={(a) => setCustomMargin(a + 75)} from="home"/>

            <div style={{top: customMargin}}  className={`c-shadow flex mx-3 screen_container fixed left-3 right-3 bottom-3 top-[75px] justify-center items-center color-secondary  rounded-2xl overflow-hidden`}>
              <div className="flex-col ">

                <span className="text-3xl font-bold p-3">Contact</span>
                <div className="flex flex-row pt-4 px-3">
                  <span className="text-md font-bold px-3 color-dim-7">Phone no: </span>
                  <span className="text-md font-bold px-3">{DETAILS.phone_no}</span>
                </div>

                <div className="flex flex-row py-2 px-3">
                  <span className="text-md font-bold px-3 color-dim-7">Email id: </span>
                  <span className="text-md font-bold px-3">{DETAILS.email_id}</span>
                </div>

                <Footer1 callBackCall={() => callBackCall()} callBackWhatsapp={() => callBackWhatsapp()} />

              </div>
            </div>

          </div>
        )}
      </div>

    </div>
  )
}


export default Contact;