import React from 'react';
 
import { DETAILS } from '../files/Data';


function Footer() {
 
  return(
    <footer className={`flex border-t border-color-o bg-[#373757]`}>
      <div className="h-[50px] text-sm mx-auto flex justify-center items-center text-white"> 
        {DETAILS.footer_text}
      </div>
    </footer>
  )

}


export default Footer;