import React, { useRef, useState , useEffect, useContext} from "react";
import { isBrowser, isMobile } from 'react-device-detect';

import { DETAILS } from '../files/Data';

import '../index.css';
import Header from "../element/Header";
import Footer from "../element/Footer";
import ButtonNav from "../element/ButtonNav";
import SplashPage from "../element/SplashPage";
import ImageList from "../element/ImageList";



function Gallery() {
  //data
  const [customMargin, setCustomMargin] = useState(75);
  const [loadingTime, setLoadtingTime] = useState(true);


  useEffect(() => {
    document.title = 'Gallery | ' + DETAILS.site_title;

    const fetchData = async () => {
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1900);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);
 

  function callBackCall(){ window.location.href = `tel:${DETAILS.phone_no}`; }
  function callBackWhatsapp(){ window.open(`https://wa.me/${DETAILS.phone_no}`, '_blank'); } 



  return(
    <div>
      <head>
        <title>VIP Road Escort Service Zirakpur</title>
        <meta name="description" content="VIP Road Escort Service Zirakpur, Chandigarh, Mohali and Panchkula are professional in handling the lusty side of every men with very cheap rates and good service.Book sexy female escort for sexual needs. Contact number 8168682300. Services are available 24*7 . Our service is the fastest door delivery service of escort with COD. Book online on WhatsApp or on the call for hot and sexy girls . Our escort service girls are prepared for both IN-OUT call services with full sex . We have best and  professional escort call which you should must try. Give us the chance to serve you. Contact number 8168682300" />
      </head>

      <div className="flex flex-col">
        {( loadingTime) ? ( <SplashPage /> ) : (
          <div className="flex flex-col justify-between" >

            <Header callBack={(a) => setCustomMargin(a + 75)} from="gallery"/>

            <div style={{top: customMargin}}  className={`c-shadow  mx-3 color-secondary screen_container fixed left-3 right-3 top-[75px] ${isMobile ? 'bottom-[75px]' : "bottom-3"} rounded-2xl overflow-hidden `}>
              <div className="h-full rounded-xl custom-scrollbar">

                <div className="h-[30vh] flex justify-center items-center scale-[1.5]">
                  <span className="text-3xl font-bold" >Gallery</span>
                </div>

                <div className="px-4"> <ImageList /> </div>

                <Footer />

              </div>
            </div>

            {(isMobile) && <ButtonNav callBackCall={() => callBackCall()} callBackWhatsapp={() => callBackWhatsapp()} /> }

          </div>
        )}
      </div>

    </div>
  )
}


export default Gallery;