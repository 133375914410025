import React, { useRef, useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { DETAILS } from '../files/Data';

function ImageList() {
  const [customHeight, setCustomHeight] = useState(0);
  const imgRef = useRef(null);


  useEffect(() => {
    if (imgRef.current) {
      setCustomHeight((imgRef.current.clientWidth / 3) * 4);
    }
  }, [imgRef.current]);



  return (
    <div className="flex flex-col mx-4 my-8">
      <ul className={`grid ${isMobile ? "grid-cols-2" : "grid-cols-4"} gap-4`}>

        {DETAILS.imgList.map((item, index) => (
          <li key={index} className="color-primary rounded-2xl overflow-hidden" style={{ height: `${customHeight}px` }}>
            <img ref={imgRef} src={item.link} className="w-full h-full object-cover" alt={`Image ${index + 1}`} />
          </li>
        ))}
        
      </ul>
    </div>
  );
}

export default ImageList;
