import React from 'react';

import HeaderTitle from './HeaderTitle';


const SplashPage = () => {
  return (
    <div className="h-full w-full fixed flex flex-col justify-between items-center bg-fj">

      <div></div>

      <div className="flex flex-row  items-center mx-1 scale-[2]">
        <HeaderTitle />
      </div>

      <span className="p-2.5 text-base py-5 mb-4">Please wait...</span>

    </div>
  );
};

export default SplashPage;
