import React, { useRef, useState , useEffect, useContext } from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { DETAILS } from '../files/Data';

  
function Disclimer() {
  const [ dialogOpen, setDialogStatus ] = useState(true);
  function closeSite() {
     window.history.back(); 
    }

 
  return(
    <div className={`flex w-screen h-screen justify-center items-center z-30 ${dialogOpen ? "c_visible blur_bg" : "c_hidden"}`}>
      <div className={`menu ${dialogOpen ? "slideDown" : "slideUp"} ${isMobile && "w-full h-3/5"}`}>


        <BrowserView>
          <div className='flex flex-col color-disclaimer w-[500px] h-[600px] rounded-3xl d-shadow p-6'>

            <span className='font-bold text-2xl'>Disclaimer</span>

            <div className="flex-1 my-4 px-2 custom-scrollbar_2">
              {DETAILS.disclaimer.split('\n').map((line, index) => (
                <div className='pt-1' key={index}>{line}</div>
              ))}
            </div>

              <div className="flex flex-row justify-end items-center"> 
                <button className={`button_3 button_small`} onClick={() => closeSite()}>
                  <span className="font-bold px-2 text-base">Decline</span>
                </button>

                <button className={`button_1 button_small`} onClick={() => setDialogStatus(false)}>
                  <span className="font-bold px-2 text-white text-base">Accept</span>
                </button>
              </div>

          </div>

        </BrowserView>


        <MobileView className="flex flex-col color-disclaimer rounded-3xl d-shadow mx-5 h-full px-4 py-4">

          <span className='font-bold text-2xl px-2'>Disclaimer</span>
          
          <div className="flex-1 my-4 px-2 custom-scrollbar_2">
              {DETAILS.disclaimer.split('\n').map((line, index) => (
                <div className='pt-1' key={index}>{line}</div>
              ))}
            </div>


          <div className="flex flex-row justify-end items-center"> 
            <button className={`button_3 button_small`} onClick={() => closeSite()}>
              <span className="font-bold px-2 font-base">Decline</span>
            </button>

            <button className={`button_1 button_small`} onClick={() => setDialogStatus(false)}>
              <span className="font-bold px-2 text-white text-base">Accept</span>
            </button>
          </div>

        </MobileView>

      </div>
    </div>
  )
}


export default Disclimer;