 
const DETAILS = {
  page_title: "Cruiseltz",
  site_title: "VIP Road Escort Service Zirakpur",
  site_sub_title: "VIP Road Escort Service Zirakpur, Chandigarh, Mohali and Panchkula are professional in handling the lusty side of every men with very cheap rates and good service.Book sexy female escort for sexual needs. Contact number 8168682300.",
  d_title: "VIP Road Escort Service Zirakpur",
  d_des: "VIP Road Escort Service Zirakpur, Chandigarh, Mohali and Panchkula are professional in handling the lusty side of every men with very cheap rates and good service.Book sexy female escort for sexual needs. Contact number 8168682300. Services are available 24*7 . Our service is the fastest door delivery service of escort with COD. Book online on WhatsApp or on the call for hot and sexy girls . Our escort service girls are prepared for both IN-OUT call services with full sex . We have best and  professional escort call which you should must try. Give us the chance to serve you. Contact number 8168682300",
  phone_no: "+918168682300",
  email_id: "cruiseltz1@gmail.com",
  

  disclaimer: "I'm 18 years old.\n The content of any advertisement that claims to offer sexual services in exchange for money is not permitted.\n Any publication of obscene material containing genital organs is not permitted.\n Any such advertisements will instantly be reported to the authorities, and he/she will be liable to bear the consequences.\n By publishing an advertisement in Cruiseltz, the ad publishers confirm that they can have access to the contents with full rights.\n And they also affirm that the advertisement belongs to the individuals who are aged more than 18 years, and he/she has given their approval for publishing the advertisement in Cruiseltz.\n By clicking the 'Accept' button, the User certifies that he or she is Over 18 and relieves this service providers, owners and creators of cruiseltz.com of responsibility for the content and for the use of this service.",
  footer_text: "Copyrights 2024. All Rights Reserved Zirakpur Escorts",
  
  faqList: [
    { title: 'What is the starting price of escorts?', subtitle: 'The starting price of escorts is 10k per night.' },
    { title: 'Is home service also available?', subtitle: 'Yes it is available all through Chandigarh Zirakpur Mohali Panchkula and many more places.' },
    { title: 'Is there is any advance booking fees?', subtitle: 'No , only hand to hand cash payment at our escort.' },
    { title: 'Are these photos are same as the escort girl?', subtitle: 'Yes the photos are real and escort girl will be the same.' },
  ],
  
  main_img_link: "/files/img/image_22.png",
  imgList: [
    { title: '', link: '/files/img/image_2.jpeg' },
    { title: '', link: '/files/img/image_3.jpeg' },
    { title: '', link: '/files/img/image_5.jpeg' },
    { title: '', link: '/files/img/image_6.jpeg' },
    { title: '', link: '/files/img/image_7.jpeg' },
    { title: '', link: '/files/img/image_8.jpeg' },
    { title: '', link: '/files/img/image_9.jpeg' },
    { title: '', link: '/files/img/image_10.jpeg' },
  ]


}



module.exports = {  DETAILS };
