import React, { useRef, useState , useEffect, useContext} from "react";
import { DETAILS } from '../files/Data';


//icon
import { FiMinus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";



function FaqEl() {

  const [numbers, setNumbers] = useState([]);
  const checkNumber = (num) => { return numbers.includes(num); };
  const addNumber = (num) => {
    if (checkNumber(num)) { setNumbers(numbers.filter(n => n !== num)); } 
    else { setNumbers([...numbers, num]); }
  };
  


  return(
    <div className={`flex flex-col mx-4 my-8`}>
      <span className="font-bold text-3xl my-2 mx-4">FAQ</span>

      <ul>
        {DETAILS.faqList.map((item, index) => (
          <li className="flex flex-col color-primary rounded-xl my-2">
            <div className="flex flex-row justify-between items-center px-5" onClick={() => addNumber(index)}> 
              <span className="py-3 font-bold color-dim-8 text-lg">{item.title}</span>

              {(!checkNumber(index)) ? ( <FiMinus className="h-5 w-6 text-color"/> ) 
              : ( <IoMdAdd className="h-5 w-6 text-color"/> )}

            </div>

            {(!checkNumber(index)) && ( <div className="border-t border-color py-3 px-5 color-dim-7">{item.subtitle}</div>)}
          </li>
        ))}
      </ul>

    </div>
  )
}


export default FaqEl;