import React, { useRef, useState , useEffect, useContext} from "react";
import { isBrowser, isMobile } from 'react-device-detect';

import { DETAILS } from '../files/Data';


import '../index.css';
import Header from "../element/Header";
import Footer from "../element/Footer";
import ButtonNav from "../element/ButtonNav";
import ButtonNav1 from "../element/ButtonNav1";
import SplashPage from "../element/SplashPage";


import Disclimer from "../element/Disclimer";
import FaqEl from "../element/FaqEl";
import ImageList from "../element/ImageList";
 


function HomePage() {
  //data
  const [customMargin, setCustomMargin] = useState(75);
  const [loadingTime, setLoadtingTime] = useState(true);


  useEffect(() => {
    document.title = DETAILS.site_title;
    const fetchData = async () => {
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1900);
      setLoadtingTime(false);
    };

    fetchData();
  }, []);
 
  
  function callBackCall(){ window.location.href = `tel:${DETAILS.phone_no}`; }
  function callBackWhatsapp(){ window.open(`https://wa.me/${DETAILS.phone_no}`, '_blank'); } 



  return(
    <div>
      <head>
        <title>VIP Road Escort Service Zirakpur | Cruiseltz</title>
        <meta name="description" content="VIP Road Escort Service Zirakpur, Chandigarh, Mohali and Panchkula are professional in handling the lusty side of every men with very cheap rates and good service.Book sexy female escort for sexual needs. Contact number 8168682300. Services are available 24*7 . Our service is the fastest door delivery service of escort with COD. Book online on WhatsApp or on the call for hot and sexy girls . Our escort service girls are prepared for both IN-OUT call services with full sex . We have best and  professional escort call which you should must try. Give us the chance to serve you. Contact number 8168682300" />
      </head>

      <div className="flex flex-col">
        {( loadingTime) ? ( <SplashPage /> ) : (
          <div className="flex flex-col justify-between" >

            <Disclimer />

            <Header callBack={(a) => setCustomMargin(a + 75)} from="home"/>


            <div style={{top: customMargin}} className={`c-shadow mx-3 color-secondary screen_container fixed left-3 right-3 ${isMobile ? 'bottom-[75px]' : "bottom-3"} rounded-2xl overflow-hidden `}>
              <div className={`h-full rounded-xl custom-scrollbar`}>


                {/* //top section */}
                <div className={`m-4 flex ${isMobile ? "flex-col" : "flex-row"}`}>

                  <div className={`flex flex-col justify-center items-center  h-[50vh] ${isMobile ? "w-full" : "w-1/2"}`}>
                    
                    <span className="font-bold text-3xl py-3">{DETAILS.site_title}</span>
                    <span className="text-base text-center w-4/5">{DETAILS.site_sub_title}</span>
                    <div className="mt-4 scale-[0.80]">
                      <ButtonNav1 callBackCall={() => callBackCall()} callBackWhatsapp={() => callBackWhatsapp()} />
                    </div>

                  </div>
                  
                  <div className={`h-[50vh] ${isMobile ? "w-full" : " w-1/2"}`}>
                    <img src={DETAILS.main_img_link} className="w-auto mx-auto h-full object-cover overflow-hidden" alt=''></img>
                  </div>
  
                </div>



                {/* //detail section */}
                <div className={`my-8 ${isBrowser ? "mx-8" : "mx-4"} `}>
                  <div className="font-bold text-2xl py-2">{DETAILS.d_title}</div>
                  <div className="color-dim-8">{DETAILS.d_des}</div>
                </div>

                <div className={`${isBrowser && "px-4"}`}> <ImageList /> </div>

                <div className={`${isBrowser && "px-4"}`}> <FaqEl /> </div>

                <Footer />

              </div>
            </div>

            {(isMobile) && <ButtonNav callBackCall={() => callBackCall()} callBackWhatsapp={() => callBackWhatsapp()} /> }
          </div>
        )}
      </div>

    </div>
  )
}


export default HomePage;