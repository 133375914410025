import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ThemeProvider from './ThemeProvider';


import './index.css';
import './build.css';
import './css/index.css';

 
//pages
import HomePage from "./page/HomePage";
import Contact from './page/Contact';
import Gallery from './page/Gallery';



function App() {

  return (
    <ThemeProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={ <HomePage /> }/>
            <Route path="/home" element={ <HomePage /> }/> 
            <Route path="/contact" element={ <Contact /> }/> 
            <Route path="/gallery" element={ <Gallery /> }/> 

            <Route path="*" element={ <HomePage /> }/>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
