import React, { useState , useEffect, useContext } from 'react';

import { RiWhatsappLine } from "react-icons/ri";
import { FaPhone } from "react-icons/fa6";

  
function ButtonNav1({callBackCall, callBackWhatsapp}) {

  function callClick(){ callBackCall(); }
  function whatsappClick(){ callBackWhatsapp(); }

 
  return(
    <footer className={``}>
      <div className="screen_container h-[75px] m-auto flex flex-row justify-center  items-center"> 

        <button className={`button_1 flex flex-row items-center justify-center mx-2 px-4 py-3`} onClick={() => callClick()}>
          <FaPhone className="w-6 h-6" style={{ color: 'white' }} />
          <span className=" font-bold px-2 text-white">Call Me</span>
        </button>

        <button className={`button_2 flex flex-row items-center justify-center mx-2 px-4 py-3`} onClick={() => whatsappClick()}>
          <RiWhatsappLine className="w-6 h-6" style={{ color: 'white' }} />
          <span className=" font-bold px-2 text-white">Whatsapp</span>
        </button>

      </div>
    </footer>
  )
}


export default ButtonNav1;