import React, { useRef, useState , useEffect, useContext } from 'react';

//theme context
import { ThemeContext } from '../ThemeProvider';


import AppIcon_W from "../images/app_icon_w.png"
import AppIcon_B from "../images/app_icon_b.png"
 

function HeaderTitle() {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
 
 
  return(
    <div>
      {(isDarkMode ? (<img src={AppIcon_W} className="h-[75px] py-4" alt=''></img> ) : 
        ( <img src={AppIcon_B} className="h-[75px] py-4" alt=''></img> ))}
    </div>
  )
}


export default HeaderTitle;