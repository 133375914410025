import React, { useRef, useState , useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//theme context
import { ThemeContext } from '../ThemeProvider';


import HeaderTitle from './HeaderTitle';

 
//icon
import { IoMdHome } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { MdOutlineLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { IoMenu } from "react-icons/io5";
import { RiGalleryFill } from "react-icons/ri";
import { FaPhone } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";


  
function Header({callBack , from}) {


  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [ editMode, setEditMode ] = useState(false);
  function ToggleMode(){ toggleTheme(); }


  const [ menuOpen, setMenuStatusS ] = useState(false);
  function setMenuStatus(d){
    setDefaultMenuOpen(true);
    setMenuStatusS(d)
  }
  const [ defaultMenuOpen, setDefaultMenuOpen ] = useState(false);
  
  
  
  //sub div height
  const divRef = useRef(null);
  useEffect(() => {
    if(menuOpen) {
      const height = divRef.current.getBoundingClientRect().height;
      callBack(height);
    } else { callBack(0); }
  }, [menuOpen]);

 
  return(
    <header className={`z-10 fixed top-0 left-0 right-0  `}>


      <BrowserView>
        <div className="flex flex-row screen_container items-center px-4 justify-between w-full"> 
        
          <Link to='/' className="flex flex-row items-center mx-1 cursor-pointer">
            <HeaderTitle />
          </Link>

          <nav className='flex flex-row items-center mx-3'>
            <ul className="flex flex-row">
              {(from != "home" && (
                <li className="px-4 text-md font-medium  item-select">
                  <Link to='/' >Home</Link>
                </li>
              ))}

              {(from != "gallery" && (
                <li className="px-4 text-md font-medium  item-select">
                 <Link to='/gallery' >Gallery</Link>
                </li>
              ))}

              {(from != "contact" && (
                <li className="px-4 text-md font-medium  item-select">
                  <Link to='/contact' >Contact</Link>
                </li>
              ))}
            </ul>

            <div onClick={() => ToggleMode()} className='ml-5'>
              {(isDarkMode) ? ( <MdDarkMode className="h-5 w-6" style={{color: "white"}}/> ) 
              : ( <MdOutlineLightMode className="h-5 w-6" style={{color: "black"}}/> )}
              </div>
          </nav>

        </div>
      </BrowserView>


      <MobileView>
        <div className="flex flex-col screen_container items-center"> 

          <div className='flex flex-row px-4 justify-between w-full'>

            <Link to='/' className="flex flex-row  items-center mx-1  cursor-pointer">
              <HeaderTitle />
            </Link>

            <button className="flex flex-row  items-center mx-3  cursor-pointer" onClick={() => setMenuStatus(!menuOpen)}>
              {(menuOpen === false) ? ( <IoMenu className="h-8 w-8"/> ) 
              : ( <IoClose className="h-8 w-8"/> )}
            </button>

          </div>

          {defaultMenuOpen && ( 
            <div className={`w-full menu ${menuOpen ? "slideDown" : "slideUp"}`} ref={divRef}>
              <div className='rounded-3xl color-secondary c-shadow mx-3 mb-3 py-2'>

              {(from != "home" && (
                <Link to='/' className="flex flex-row items-center justify-between w-full py-3 px-8">
                  <div className='flex flex-row'>
                    <IoMdHome className="h-5 w-5" />
                    <span className='px-3 font-bold text-md'>Home</span>
                  </div>
                  <div>
                    <FaExternalLinkAlt className="h-5 w-5" style={{ color: '#2C4188' }} />
                  </div>
                </Link>
              ))}


              {(from != "gallery" && (
                <Link to='/gallery' className="flex flex-row items-center justify-between w-full py-3 px-8">
                  <div className='flex flex-row'>
                    <RiGalleryFill className="h-5 w-5" />
                    <span className='px-3 font-bold text-md'>Gallery</span>
                  </div>
                  <div>
                    <FaExternalLinkAlt className="h-5 w-5" style={{ color: '#2C4188' }} />
                  </div>
                </Link>
              ))}


              {(from != "contact" && (
                <Link to='/contact' className="flex flex-row items-center justify-between w-full py-3 px-8">
                  <div className='flex flex-row'>
                    <FaPhone className="h-5 w-5" />
                    <span className='px-3 font-bold text-md'>Contact</span>
                  </div>
                  <div>
                    <FaExternalLinkAlt className="h-5 w-5" style={{ color: '#2C4188' }} />
                  </div>
                </Link>
              ))}
                

                <button className="flex flex-row items-center justify-between w-full py-3 px-8" onClick={() => ToggleMode()}>
                  <div className='flex flex-row'>
                    {(isDarkMode) ? ( <MdDarkMode className="h-5 w-6" style={{color: "white"}}/> ) 
                    : ( <MdOutlineLightMode className="h-5 w-6" style={{color: "black"}}/> )}
                    <span className='px-3 font-bold text-md'>Appearance</span>
                  </div>
                  <div className='font-bold color-dim-6'>
                    {(isDarkMode) ? ( "Dark" ) : ( "Light" )}
                  </div>
                </button>

              </div>
            </div>
          )}
            
        </div>
      </MobileView>
      
    </header>
  )
}


export default Header;